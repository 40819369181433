import Redis, { RedisOptions } from 'ioredis';
import redisConfig from "../../data/redisConfig.json";
import { CountryCode, RedisKey } from "../constants";

class RedisClient{
    private static _instance: Redis;      
    static getInstance() {
        try {
            const REDIS_DISABLED=process.env.REDIS_DISABLED||"0";
            if(REDIS_DISABLED && REDIS_DISABLED=="1")return null;

            if (this._instance) {
                return this._instance;
            }
            const port=process.env.REDIS_PORT && parseInt(process.env.REDIS_PORT);
            const host=process.env.REDIS_HOST;
            if(!port || !host)return null;

            const options: RedisOptions = {
                host: host,
                port:port,
                lazyConnect: true,
                showFriendlyErrorStack: true,
                enableAutoPipelining: true,
                maxRetriesPerRequest: 0,
                retryStrategy: (times: number) => {
                  if (times > 3) {
                    throw new Error(`[Redis] Could not connect after ${times} attempts`);
                  }          
                  return Math.min(times * 200, 1000);
                },
            };

            this._instance = new Redis(options);   
            this._instance.on('error', (error: unknown) => {
                console.error('[Redis] Error connecting', error);
            });
            return this._instance;
        } catch (error) {
            console.error("[Redis] Could not create a Redis instance");
            console.error(error);
            return null;
        }        
    }
}

const isDisabled=process.env.REDIS_DISABLED && parseInt(process.env.REDIS_DISABLED)||0;
class RedisHelper{
    private readonly _client:Redis|null;
    public constructor() {
        this._client=RedisClient.getInstance();
    }

    private setData=async(key:string,value:any, expiryInSeconds:number=86400)=>{
        if(!this._client || isDisabled===1)return null;
        return await this._client.set(key,JSON.stringify(value),"EX",expiryInSeconds);  //seconds(86400) - 24 hours     
    }

    private getData=async(key:string)=>{
        try {
            if(!this._client || isDisabled===1)return null;
            const _result=await this._client.get(key);
            if(_result)
                return JSON.parse(_result);
            else
                return null;
        } catch (error) {
            console.error("RedisHelper - ",key,error);
            return null;
        }      
     }

    public getValue=async(redisKey:RedisKey, countryCode:CountryCode|null=null, keyPostFix:string|null=null)=>{
        try {
            const keyName=(countryCode && `${redisKey}_${countryCode.toUpperCase()}` || redisKey)+(keyPostFix && `_${keyPostFix}`||"");
            return await this.getData(keyName);
        } catch (error) {
            console.error("getValue - ",redisKey,error);
            return null;
        }      
    }

    public setValue=async(redisKey:RedisKey, value:any, countryCode:CountryCode|null=null, keyPostFix:string|null=null)=>{
        try {
            const keyName=(countryCode && `${redisKey}_${countryCode.toUpperCase()}` || redisKey)+(keyPostFix && `_${keyPostFix}`||"");
            const _config= redisConfig.find(f=> (countryCode && f.countryCode?.includes(countryCode)|| true) && f.redisKeyPrefix==redisKey);
            if(_config)
                return await this.setData(keyName,value, (_config.expiryInHours||1) * 3600);
           else
                return await this.setData(keyName,value, 2*86400); /*86400 EQUALS TO 1 DAY */
        } catch (error) {
            console.error("setValue - ",redisKey,error);
            return null;
        }      
    }
}
export default new RedisHelper();