import NextImage, { ImageLoaderProps, ImageProps } from "next/image";

export default function imageLoader({src, quality, width}:ImageLoaderProps){ 
    if(src.substring(0,1)==='/')
        src=src.substring(1); 
    //return `${src}?w=${width}&q=${quality||75}`.replace(/,/g, '%2C');
    // return `${process.env.CDN_URL},quality=${quality||85},width=${width}/${src}`.replace(/,/g, '%2C');
    return `${process.env.CDN_URL}/${src}?aio=w-${width};`.replace(/,/g, '%2C');
}
export  function imageLoaderfitCrop({src, quality, width}:ImageLoaderProps){ 
    if(src.substring(0,1)==='/')
        src=src.substring(1); 
    return `${process.env.CDN_URL}/${src}?aio=w-${width};`.replace(/,/g, '%2C');
    // return `${process.env.CDN_URL},fit=crop,quality=${quality||85},width=${width}/${src}`.replace(/,/g, '%2C');
}
export function cmsLoader({src, quality, width}:ImageLoaderProps){ 
    if(src.substring(0,1)==='/')
        src=src.substring(1); 
    return `${process.env.CMS_CDN_URL}/${src}?aio=w-${width};`.replace(/,/g, '%2C');
    // return `${process.env.CMS_CDN_URL},quality=${quality||85},width=${width}/${src}`.replace(/,/g, '%2C');
}

export function staticLoader({src, quality, width}:ImageLoaderProps){ 
    return `${src}?w=${width}&q=${quality||80}`.replace(/,/g, '%2C');
}

//loader={(arg:any)=>customImageLoader({...arg, height:640})}
export function customImageLoader(props:{src:string, width:number, quality?:number|undefined, height?:number}){  
    if(process.env.ENVIRONMENT==='DEV')return props.src;
    if(props.src.substring(0,1)==='/')
        props.src=props.src.substring(1);
    if(props.height)
        return `${process.env.IMAGE_RESIZE_URL},quality=${props.quality||85},width=${props.width},height=${props.height}/${props.src}`.replace(/,/g, '%2C');
    else
        return `${process.env.IMAGE_RESIZE_URL},quality=${props.quality||85},width=${props.width}/${props.src}`.replace(/,/g, '%2C');
}

export function getCDNPath(src:string, width:number, height:number, quality:number=85){  
    if(src.substring(0,1)==='/')
        src=src.substring(1);
    return `${process.env.CDN_URL},quality=${quality||85},width=${width},height=${height}/${src}`.replace(/,/g, '%2C');   
}

export function marketPlaceLoader({src, width}:ImageLoaderProps){ 
    return `${src}?aio=w-${width};`.replace(/,/g, '%2C');
}
