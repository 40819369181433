import Image from "next/image"
import { staticLoader } from "../../../lib/helpers/imageLoader"
import loaderImage from '../../../public/assets/images/diLoader.gif'
import { useEffect } from "react"

type props = {
    classNameP?: string
}
export default function Loader({ classNameP }: props) {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);
    return <div className={`overlayLoader ${classNameP || ''}`}>
        <Image
            src={loaderImage}
            alt='loader'
            loading="eager"
            className='img-responsive'
            loader={staticLoader}
            width={40}
            height={40}
        />
    </div>
}