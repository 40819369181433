export enum CountryCode {
    INR = "in",
    AED = "ae",
}

export enum ModuleType {
    INTERIOR = "INTERIOR",
    HOMECO = "HOMECO",
    REFERANDEARN = "REFERANDEARN"
}

export enum LoginType {
    LOGIN = "LOGIN",
    REGISTER = "REGISTER"
}

export enum ElemenetType {
    button = "button",
    li = "li",
    span = "span"
   
}

export enum ImageResizeType{
    Normal="Normal",
    Blur="Blur"
}

export enum SourceType{
    LEAD = "LEAD",
    REFERANDEARN = "REFERANDEARN",
    EXITINTENTPOPUP = "EXITINTENTPOPUP"
}

export enum WishlistType{
    CATALOGUE = "CATALOGUE",
    DESIGN_IDEA = "DESIGN_IDEA"
}

export enum DynamicElementType {
    h1 = "h1",
    h2 = "h2",
    h3 = "h3"
}