import { BeatsAPI, IncoAPI } from '../helpers/http-client';
import { getKeyByValue } from '../helpers/common';
import { CountryCode, RedisKey } from '../constants';
import RedisClient from '../../lib/helpers/redis-client';

class MasterService {
    getmaster = async (countryCode: CountryCode,data:string) => {
        try {
            const _response:any={}, _attributes=[];
            const attributesData = data.split(",");
            const keyName = RedisKey.GetMasterList;
            const siteurl = process.env.SITE_URL || "";
            for(const atr of attributesData){
                const redisResult = await RedisClient.getValue(keyName, countryCode, atr.toUpperCase());
                if (redisResult)
                {
                    _response[atr]=redisResult  
                }else
                {
                    _attributes.push(atr);
                }
            }
            if(_attributes && _attributes.length>0)
            {
                const result = await IncoAPI.get(`v2/master?attributes=${_attributes.join(',')}`,
                {
                    headers: {
                        countrycode: countryCode,
                        siteurl:siteurl
                    },
                });
                
                if (result && result.status == 200) {
                    const { data } = result.data;                    
                    for(const atr of _attributes){
                        const _value=data[atr];
                        await RedisClient.setValue(keyName, _value, countryCode, atr.toUpperCase());
                        _response[atr]=_value;  
                    }
                }
            }
            return _response;       
        } catch (error) {
            console.error(error);
            return [];
        }

    }
}

export default new MasterService();