import Cookies from "js-cookie";
export function setCookie(name: string, value: string,days?:number) {
    if(days){
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        Cookies.set(name, value, { expires: date });
    }
    else
        Cookies.set(name, value);
}

export function getCookie(name: string) {
    const cookieData:string = Cookies && Cookies.get(name) || "";
    try {
        if(!cookieData || typeof(cookieData)==='string')return cookieData || "";
        else
        {
            const result = JSON.parse(cookieData);
            return result;
        }
    } catch (e) {
        console.log("Cookies Exception - ",e);
        return "";
    }
}

export function removeCookie(name: string) {
    Cookies.remove(name);
}