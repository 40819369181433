import '@/public/assets/css/global.css';
import '@/lib/helpers/extensionMethods';
import { CountryCode } from '@/lib/constants';
import { GlobalContext } from '@/lib/context/GlobalContext';
import { useEffect, useState } from "react";
import { API } from "@/lib/helpers/http-client";
import masterService from "@/lib/services/masterService";
import { ICityData, IGlobalProps, IMarketPlaceMenu, IPageMenu } from '@/interfaces';
import dynamic from 'next/dynamic';
import localFont from 'next/font/local';
import { getCookie, setCookie } from '@/lib/helpers/Cookie';
import { pushRoute } from '@/lib/helpers/common';
import { IWishlistProducts } from '@/interfaces/IDashboard';
import marketPlaceService from '@/lib/services/marketPlaceService';
import Loader from '@/components/shared/loader/Loader';
const Layout = dynamic(() => import('@/components/layout/Layout').then(mod => mod.Layout));
type props = {
  Component: any;
  pageProps: any;
  countryCode: CountryCode;
  isDubaiPage: Boolean;
  pageURL: any;
  isMobileView: boolean;
  isHideLayout: boolean;
  isUserLoggedIn: boolean;
  data: {
    utmSource: string;
    countryMaster: [],
    cityMaster: ICityData[],
    headerData: IPageMenu[],
    footerData: IPageMenu[],
    marketplaceHeaderData: IMarketPlaceMenu[]
  }
}

const fonts = localFont({
  src: [
    {
      path: '../public/assets/fonts/lato-regular-webfont.woff2'
    },
    {
      path: '../public/assets/fonts/lato-regular-webfont.woff'
    }
  ],
  weight: 'normal',
  style: 'noraml',
  display: 'swap',
  fallback: ['latoregular', 'sans-serif']
});
function MyApp({ Component, pageProps, countryCode, pageURL, isMobileView, isHideLayout, data, isDubaiPage, isUserLoggedIn }: props) {
  const [isHideFloatingWidget, setHideFloatingWidget] = useState(false);
  const isLogin = JSON.parse(getCookie('LOGIN') || "{}")?.token || "";
  const [globalProps] = useState<IGlobalProps>();
  const [loading, setLoading] = useState(false);
  const toggleFloatingWidget = (isHide: boolean) => {
    setHideFloatingWidget(isHide);
  }

  const [wishlistItems, setWishlistItems] = useState<[{ type: string, prodId: number }] | any>([]);

  const addToWishlist = async (productId: number, wishlistType: string) => {
    if (isLogin === "") {
      pushRoute(`${process.env.SITE_URL}/in/login?redirectPath=${pageURL}`);
      localStorage.setItem('wishlistAction', JSON.stringify({ productId, wishlistType }));
    } else {
      const wishlistItems = JSON.parse(localStorage.getItem('wishlist') || "[]");
      const existingItemIndex = wishlistItems.findIndex((item: any) => item.prodId === productId);
      if (existingItemIndex !== -1) {
        wishlistItems.splice(existingItemIndex, 1);
      } else {
        wishlistItems.push({ type: wishlistType, prodId: productId });
      }
      localStorage.setItem('wishlist', JSON.stringify(wishlistItems));
      setWishlistItems(wishlistItems);
      try {
        await API.post(`/api/addtowishlist?wislistType=${wishlistType}&prodId=${productId}`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const addToCart = async (products: { productId: number, quantity: number, isProductSelected: boolean }[]) => {
    if (isLogin) {
      try {
        products && products.length > 0 && await API.post('/api/addtocart', products)
        const cartItems = await API.get('/api/addtocart?type=city');
        if (cartItems && cartItems.data && Object.keys(cartItems.data)) {
          const formattedCartItems = cartItems.data.productList.map((f: any) => ({
            productId: f.productId,
            quantity: f.quantity,
            isProductSelected: f.isProductSelected
          }));
          setCookie('cart', JSON.stringify(formattedCartItems));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const cartItems = JSON.parse(getCookie('cart') || '[]');
      products && products.forEach(({ productId, quantity, isProductSelected }) => {
        const existingItemIndex = cartItems.findIndex((item: any) => item.productId === productId);
        if (existingItemIndex !== -1) {
          if (quantity === 0) {
            cartItems.splice(existingItemIndex, 1);
          } else {
            cartItems[existingItemIndex].quantity = quantity;
            cartItems[existingItemIndex].isProductSelected = isProductSelected;
          }
        } else {
          if (quantity > 0) {
            cartItems.push({ productId, quantity, isProductSelected: true });
          }
        }
      });
      setCookie('cart', JSON.stringify(cartItems));
    }
  };

  useEffect(() => {
    const updateCart = () => {
      const isCartUpdated = getCookie('setCartUpdated');
      if (isLogin !== '' && isCartUpdated !== 'true') {
        try {
          setTimeout(async () => {
            await addToCart([]);
            setCookie("setCartUpdated", "true");
          }, 100);
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      }
    };
    updateCart();
  }, []);
  const marketPlaceNavSchema: IPageMenu[] = data?.marketplaceHeaderData?.map((f) => {
    return {
      pageName: f.serviceName,
      pageUrl: `${process.env.SITE_URL}/online-store/${f.serviceSlug}`,
      isAbsoluteUrl: 0,
      child: f.categories && f.categories.map((m) => {
        return {
          pageName: m.categoryName,
          pageUrl: `${process.env.SITE_URL}/online-store/${f.serviceSlug}/${m.categorySlug}`,
          isAbsoluteUrl: 0,
          child: []
        }
      }) || []
    }
  }) || [];
  useEffect(() => {
    const formatData = (data: IWishlistProducts[]) => {
      const finalProds: any[] = [];
      data && data.map((f) =>
        f && f.products && f.products.map((m) => {
          finalProds.push({
            'type': f.module,
            'prodId': m.productId
          })
        })
      )
      return finalProds;
    }
    const fetchData = async () => {
      const wishlistAction = JSON.parse(localStorage.getItem('wishlistAction') || "null");
      try {
        if (wishlistAction && isLogin != '') {
          await addToWishlist(wishlistAction.productId, wishlistAction.wishlistType);
          localStorage.removeItem('wishlistAction');
        }
        const response: any = isLogin != '' && await API.post('/api/getWishlist');
        const result = response?.data || []
        if (result) {
          localStorage.setItem('wishlist', JSON.stringify(formatData(response.data)));
          setWishlistItems(formatData(response.data));
        }
      } catch (error) {
        localStorage.setItem('wishlist', JSON.stringify([]));
      }
    };
    fetchData();
  }, [isLogin]);
  const getComponent = () => {
    if (isHideLayout)
      return <Component {...pageProps} />
    else {
      return (
        <Layout HeaderData={data.headerData} FooterData={data.footerData} utmSource={data.utmSource} marketPlaceHeaderData={data.marketplaceHeaderData}>
          <Component {...pageProps} navSchemaData={data.headerData || []} marketPlaceNavSchema={marketPlaceNavSchema || []} />
        </Layout>
      )
    }
  }

  return (<>
    <GlobalContext.Provider value={{
      props: {
        cityMaster: data.cityMaster,
        countryCode,
        countryMaster: data.countryMaster,
        pageURL,
        isMobileView,
        globalProps,
        isHideFloatingWidget,
        pageProps,
        addToWishlist,
        wishlistItems,
        isDubaiPage,
        addToCart,
        isUserLoggedIn,
        setLoading,
      },
      funs: {
        toggleFloatingWidget
      }
    }}>
      <main className={fonts.className}>
        {loading && <Loader classNameP='posF' />}
        {
          getComponent()
        }
      </main>
    </GlobalContext.Provider>
  </>)
}
MyApp.getInitialProps = async (pageContext: any) => {
  const ctx = pageContext.ctx;
  const isUserLoggedIn = (JSON.parse(ctx.req.cookies.LOGIN || "{}").token || "") != "" || false;
  let isMobileView = false, isHideLayout = false;
  //const isMobileView=(ctx.req && ctx.req?.headers['user-agent']||navigator.userAgent).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i) != null;
  const cfdevicetype: string = ctx.req && ctx.req?.headers['cf-device-type']?.toString() || "";
  if (cfdevicetype && ctx.req?.headers['cf-device-type'])
    isMobileView = cfdevicetype.toLowerCase() == "mobile";
  else {
    const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : (ctx.req?.headers['user-agent'] || '');
    isMobileView = /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera\ mini|avantgo|mobilesafari|docomo|KAIOS)/i.test(userAgent);
  }
  if (ctx.query.isHideLayout && ctx.query.isHideLayout == "true")
    isHideLayout = true;

  const countryCode: CountryCode = CountryCode.INR;
  const utmSource = ctx.query.source?.toString() || ""
  const siteUrl = process.env.SITE_URL || ""
  const pageUrl = ctx.asPath == '/' ? siteUrl : `${siteUrl}${ctx.asPath}`;
  const isMarketPlace = ctx.asPath.includes('/online-store');
  const isDubaiPage = ctx.asPath.includes('/dubai') || false;
  let cityMaster = [], countryMaster = [], headerData = [], footerData = [], marketplaceHeaderData = [];
  let data;
  let getMaster;
  if (ctx.req) {
    if (isDubaiPage) {
      data = await masterService.getmaster(CountryCode.INR, "cities,country_codes,header_nav_links")
      let footerLinks = await masterService.getmaster(CountryCode.AED, "footer_nav_links")
      data.footer_nav_links = footerLinks.footer_nav_links
    } else {
      data = await masterService.getmaster(CountryCode.INR, "cities,country_codes,header_nav_links,footer_nav_links")
    }
    countryMaster = data.country_codes || [];
    cityMaster = data.cities || [];
    headerData = data.header_nav_links || [];
    footerData = data.footer_nav_links || [];
    marketplaceHeaderData = isMarketPlace ? await marketPlaceService.getMenu() : [];

  }
  else {
    if (isDubaiPage) {
      getMaster = await API.get(`/api/master?attributes=cities,country_codes,header_nav_links&countryCode=${CountryCode.INR}`);
      let footerLinks = await API.get(`/api/master?attributes=footer_nav_links&countryCode=${CountryCode.AED}`);
      if (footerLinks.status == 200 && footerLinks.data) {
        getMaster.data.footer_nav_links = footerLinks.data.footer_nav_links
      }
    } else {
      getMaster = await API.get(`/api/master?attributes=cities,country_codes,header_nav_links,footer_nav_links&countryCode=${CountryCode.INR}`);
    }
    if (getMaster.status == 200 && getMaster.data && Object.keys(getMaster.data).length > 0)
      countryMaster = getMaster.data.country_codes || [];
    cityMaster = getMaster.data.cities || [];
    headerData = getMaster.data.header_nav_links || [];
    footerData = getMaster.data.footer_nav_links || [];
    marketplaceHeaderData = isMarketPlace ? await API.get('/api/marketplaceMenu') as any : [];
  }
  return {
    pageURL: pageUrl,
    isMobileView,
    isHideLayout,
    countryCode,
    isDubaiPage,
    isUserLoggedIn,
    data: {
      utmSource,
      countryMaster,
      cityMaster,
      headerData,
      footerData,
      marketplaceHeaderData
    }
  }
};
// export function reportWebVitals(metric: NextWebVitalsMetric) {
//    console.log(metric)
// }
export default MyApp