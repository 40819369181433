import {ImageResizeType} from "../constants";
declare global {
    interface String {
      slugToText(): string;
      TrimTrailingSlash(): string;
      ToResizeImage(width:number, height:number, imageType:ImageResizeType): string;
      GetThemeEndPoint(themeSlug:string): string;
      textToSlug():string;
      capitalizeFirstWord():string;
      capitalizeSentences():string;
    }
  }
  
  String.prototype.slugToText = function () {
    const value = String(this)    
    return value.split('-').join(' ').replace(/(^\w|\s\w)/g, (m:string) => m.toUpperCase());
  };

  String.prototype.TrimTrailingSlash = function () {
    const value = String(this)    
    return value.replace(/\/+$/, '');
  };

  String.prototype.textToSlug = function () {
    const value = String(this)    
    return value.split(' ').join('-').toLowerCase();
  };
  String.prototype.capitalizeFirstWord = function () {
    const value = String(this)    
    return value.charAt(0).toUpperCase() + value.slice(1);;
  };
  String.prototype.capitalizeSentences = function () {
    const value = String(this)    
    let sentences = value.split('. ');
    let capitalizedSentences = sentences.map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1));
    let capitalizedText = capitalizedSentences.join('. ');
    return capitalizedText;
  };
  String.prototype.ToResizeImage = function (width:number, height:number, imageType:ImageResizeType=ImageResizeType.Normal) {
    const imagePath = String(this)  
    if(!imagePath)return "";
    let imgSize="";
    if(height>0 && width>0)
      imgSize=`,width=${width},height=${height}`;  
    if(imageType==ImageResizeType.Blur && process.env.BLUR_IMAGE_RESIZE_URL)
      return `${process.env.BLUR_IMAGE_RESIZE_URL.TrimTrailingSlash()},quality=10${imgSize}/${imagePath}`;

    // else if(imageType==ImageResizeType.Normal && process.env.IMAGE_RESIZE_URL)
    //   return `${process.env.IMAGE_RESIZE_URL.TrimTrailingSlash()},quality=80${imgSize}/${imagePath}`;
    else
      return imagePath 
  };

  String.prototype.GetThemeEndPoint = function (themeSlug:string) {
    const value = String(this);
    const themeSplitter=value=="modular-kitchen"&& "layouts" || "themes" ;   
    return `${value}/${themeSlug}-${themeSplitter}`;
  };

  export {}