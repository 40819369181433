export enum RedisKey {
    GetWebProductCategory="GetWebProductCategory",
    GetWebProductFilterByCategory="GetWebProductFilterByCategory",
    GetTopWebProductsByAttributeId="GetTopWebProductsByAttributeId",
    GetThemeWiseProduct="GetThemeWiseProduct",
    GetWebProductDetail="GetWebProductDetail",
    GetProjects3DList="GetProjects3DList",
    GetInteriorSummaryByCity="GetInteriorSummaryByCity",
    CategoryListByCountryCMS="CategoryListByCountryCMS",
    FeaturedPostsByCountryCMS="FeaturedPostsByCountryCMS",
    JustInCMS="JustInCMS",
    GoogleReviews="GoogleReviews",
    GetTrendList="GetTrendList",
    GetTrendListV1="GetTrendListV1",
    GetMasterList="GetMasterList",
    DesignIdeaSeo="DesignIdeaSeo",
    GetProductsByAttribute="GetProductsByAttribute",
    Get3DProductCityWise="Get3DProductCityWise",
    GetFilterWidget="GetFilterWidget",
    GetDepartmentJobId="GetDepartmentJobId",
    RelatedCatPosts="RelatedCatPosts",
    MarketPlaceHomeData="MarketPlaceHomeData",
    MarketPlaceMenu="MarketPlaceMenu",
    MarketPlaceProduct="MarketPlaceProduct",
    MarketPlaceCategory="MarketPlaceCategory",
    MarketPlaceCategoryMeta="MarketPlaceCategoryMeta",
    MarketPlaceSubCategoryMeta="MarketPlaceSubCategoryMeta",
}